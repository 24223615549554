<template>
  <v-form class="pr-2 pl-2" v-model="valid" lazy-validation ref="form">
    <div class="d-flex">
      <v-text-field
        label="Nombre"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        :value="newRow.name"
        readonly
      ></v-text-field>

      <v-text-field
        label="Precio"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.price"
      ></v-text-field>

      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        :disabled="newRow.price == this.priceObject.price"
        @click="saveClick"
      >
        <v-icon> mdi-floppy </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="info"
        class="mr-2"
        :disabled="newRow.price == this.priceObject.price"
        @click="resetNewRow"
      >
        <v-icon> mdi-restart</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { putRequest } from "../../../managrx/serverCall";
export default {
  name: "AbstractCampaignActivityPrice",
  props: {
    specieActivityPrices: Array,
    priceObject: Object,
    bySpecie: Boolean,
  },
  methods: {
    async saveClick() {
      let payload = {
        price: this.newRow.price,
      };
      if (this.bySpecie) {
        for (const activityPrice of this.specieActivityPrices) {
          let response = await putRequest(
            "/activity_price/" + activityPrice.id,
            payload
          );
          if (!response.success) {
            this.$store.dispatch("setNewNotification", response);
          }
        }
      } else {
        let response = await putRequest(
          "/activity_price/" + this.priceObject.id,
          payload
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
    },
    resetNewRow() {
      (this.newRow.price = this.priceObject.price),
        this.$refs.form.resetValidation();
    },
  },
  computed: {},
  data() {
    return {
      valid: true,
      newRow: {
        price: this.priceObject.price,
        name: this.priceObject.name,
      },
    };
  },
};
</script>
