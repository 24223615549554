<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <div class="d-flex flex-column ml-4 mr-4 mt-4">
            <div>
              <v-text-field
                :value="processedSingleCampaign.id"
                label="ID"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                v-model="name"
                label="Nombre"
                class="managrx-input mr-2"
                outlined
                dense
                :readonly="!editMode"
                :filled="!editMode"
              ></v-text-field>
              <v-text-field
                v-model="version"
                label="Versión"
                class="managrx-input mr-2"
                outlined
                dense
                :readonly="!editMode"
                :filled="!editMode"
              ></v-text-field>
              <v-text-field
                v-model="startDate"
                label="Fecha de inicio"
                type="date"
                class="managrx-input mr-2"
                outlined
                dense
                :readonly="!editMode"
                :filled="!editMode"
              ></v-text-field>
              <v-text-field
                v-model="endDate"
                label="Fecha de finalizacion"
                type="date"
                class="managrx-input mr-2"
                outlined
                dense
                :readonly="!editMode"
                :filled="!editMode"
              ></v-text-field>
            </div>
            <div>
              <v-btn
                :depressed="true"
                v-if="!editMode"
                color="warning white--text"
                class="mr-2"
                @click="editClick"
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="editMode"
                depressed
                color="info"
                class="me-2"
                @click="stopEditMode"
              >
                Atrás
              </v-btn>

              <v-btn
                v-if="editMode"
                :depressed="true"
                color="primary white--text"
                @click="saveChanges"
              >
                <v-icon> mdi-floppy</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CampaignDetail",
  props: {
    processedSingleCampaign: Object,
  },
  methods: {
    editClick() {
      this.editMode = true;
    },
    stopEditMode() {
      this.editMode = false;
      this.endDate = this.processedSingleCampaign?.endDate;
      this.startDate = this.processedSingleCampaign?.startDate;
      this.name = this.processedSingleCampaign?.name;
      this.version = this.processedSingleCampaign?.version;
    },
    saveChanges() {
      let payloadCampaign = {};
      let payloadAbstractCampaign = {};

      if (this.startDate != this.processedSingleCampaign?.startDate) {
        payloadAbstractCampaign["startDate"] = new Date(
          this.startDate
        ).toISOString();
      }
      if (this.endDate != this.processedSingleCampaign?.endDate) {
        payloadAbstractCampaign["endDate"] = new Date(
          this.endDate
        ).toISOString();
      }
      if (this.name != this.processedSingleCampaign?.name) {
        payloadAbstractCampaign["name"] = this.name;
      }
      if (this.version != this.processedSingleCampaign?.version) {
        payloadCampaign["version"] = this.version;
      }
      if (Object.keys(payloadAbstractCampaign).length) {
        this.$emit("putAbstractCampaign", payloadAbstractCampaign);
      }
      if (Object.keys(payloadCampaign).length) {
        this.$emit("putCampaign", payloadCampaign);
      }
    },
  },
  data() {
    return {
      editMode: false,
      endDate: this.processedSingleCampaign?.endDate,
      startDate: this.processedSingleCampaign?.startDate,
      name: this.processedSingleCampaign?.name,
      version: this.processedSingleCampaign?.version,
    };
  },
};
</script>
