<template>
  <v-container class="ms-0">
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!activitySlices.length"
          @click="setYieldDialog"
        >
          <v-icon> mdi-sprout </v-icon>
          <span class="px-2">Actualizar Rinde</span>
        </v-btn>
        <v-dialog v-model="yieldBulkUpdateDialog" max-width="1000">
          <v-card>
            <v-card-title class="text-h5"> Actualizar Rinde </v-card-title>
            <span class="ms-6 mt-2 text--disabled text--lighten-5">
              Filtros
            </span>
            <div class="d-flex flex-row px-5 mx-2">
              <v-select
                label="Establecimiento"
                :items="establishments"
                item-text="name"
                item-value="id"
                v-model="establishmentIdFilter"
                hide-details
                dense
                clearable
                @change="updateYieldBulkUpdateActivities"
                outlined
                type="number"
                class="managrx-input ma-2 pb-2"
              ></v-select>
              <v-select
                label="Region"
                :items="regions"
                item-text="abbreviation"
                item-value="id"
                v-model="regionIdFilter"
                hide-details
                dense
                @change="updateYieldBulkUpdateActivities"
                clearable
                outlined
                type="number"
                class="managrx-input ma-2 pb-2"
              ></v-select>
              <v-select
                label="Especie"
                :items="species"
                item-text="name"
                item-value="id"
                v-model="speciesIdFilter"
                hide-details
                dense
                @change="updateYieldBulkUpdateActivities"
                clearable
                outlined
                type="number"
                class="managrx-input ma-2 pb-2"
              ></v-select>
            </div>

            <v-card-text>
              <v-tabs
                class="mb-5 mt-3"
                v-model="yieldTab"
                background-color="white"
                color="primary"
                dark
              >
                <v-tab class="text--secondary">Actividades</v-tab>
              </v-tabs>

              <v-tabs-items v-model="yieldTab">
                <v-tab-item>
                  <div class="scrollable-holder">
                    <div
                      class="mt-2"
                      v-for="(activity, i) in yieldBulkUpdateActivities"
                      :key="activity.activitySliceId"
                    >
                      <v-form class="pr-2 pl-2" lazy-validation ref="form">
                        <div
                          class="d-flex"
                          :class="{
                            'background-muted':
                              parsedFilteredActivitySlices[i].forwardYield ===
                              activity.forwardYield,
                          }"
                        >
                          <v-icon
                            v-if="
                              parsedFilteredActivitySlices[i].forwardYield !==
                              activity.forwardYield
                            "
                            color="warning darken--2"
                            class="mr-2 mb-3 pb-3"
                            >mdi-pencil</v-icon
                          >
                          <v-text-field
                            label="Establecimiento"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.establishmentName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.activityName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Hectáreas Asignadas"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.assignedHectares"
                          ></v-text-field>
                          <v-text-field
                            label="Precio"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            v-model.number="activity.forwardPrice"
                          ></v-text-field>
                          <v-text-field
                            label="Rinde"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedFilteredActivitySlices[i].forwardYield !==
                              activity.forwardYield
                                ? `Valor original ${parsedFilteredActivitySlices[i].forwardYield}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.forwardYield"
                          ></v-text-field>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>

              <span v-if="yieldTab == 0">
                Al apretar <strong> Guardar </strong> se guardaran
                <strong> unicamente </strong> los rinde filtrados y modificados.
              </span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="unsetYieldDialog">
                Cancelar
              </v-btn>

              <v-btn
                v-if="yieldTab == 0"
                color="green darken-1"
                text
                @click="saveGranularYieldBulkUpdate"
                :disabled="!hasNewYieldValues"
              >
                Guardar Precios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!activitySlices.length"
          @click="setFreightDialog"
        >
          <v-icon> mdi-truck </v-icon>
          <span class="px-2">Actualizar Fletes</span>
        </v-btn>
        <v-dialog v-model="freightBulkUpdateDialog" max-width="1000">
          <v-card>
            <v-card-title class="text-h5"> Actualizar Fletes </v-card-title>

            <v-card-text>
              <v-tabs
                class="my-5"
                v-model="freightTab"
                background-color="white"
                color="primary"
                dark
              >
                <v-tab class="text--secondary">Por filtros</v-tab>
                <v-tab class="text--secondary">Granular</v-tab>
              </v-tabs>

              <v-tabs-items v-model="freightTab">
                <v-tab-item>
                  <div class="d-flex flex-row mx-2">
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      hide-details
                      dense
                      clearable
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      hide-details
                      v-model="speciesIdFilter"
                      clearable
                      dense
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-text-field
                      label="Nuevo Precio Flete"
                      outlined
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                      :disabled="filteredActivitySlices.length < 1"
                      v-model.number="freightBulkUpdateValue"
                    ></v-text-field>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="scrollable-holder">
                    <div
                      class="mt-2"
                      v-for="(activity, i) in freightBulkUpdateActivities"
                      :key="activity.activitySliceId"
                    >
                      <v-form class="pr-2 pl-2" lazy-validation ref="form">
                        <div
                          class="d-flex"
                          :class="{
                            'background-muted':
                              parsedActivitySlices[i].freightCostTn ===
                              activity.freightCostTn,
                          }"
                        >
                          <v-icon
                            v-if="
                              parsedActivitySlices[i].freightCostTn !==
                              activity.freightCostTn
                            "
                            color="warning darken--2"
                            class="mr-2 mb-3 pb-3"
                            >mdi-pencil</v-icon
                          >
                          <v-text-field
                            label="Establecimiento"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.establishmentName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.activityName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Precio"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            v-model.number="activity.forwardPrice"
                          ></v-text-field>

                          <v-text-field
                            label="Rinde"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            :value="activity.dynamicYield"
                          ></v-text-field>
                          <v-text-field
                            label="Hectáreas Asignadas"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.assignedHectares"
                          ></v-text-field>
                          <v-text-field
                            label="Costo del Flete"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedActivitySlices[i].freightCostTn !==
                              activity.freightCostTn
                                ? `Valor original ${parsedActivitySlices[i].freightCostTn}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.freightCostTn"
                          ></v-text-field>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <div v-if="freightTab == 0">
                <div class="d-flex flex-column w-100">
                  <h2 class="caption">
                    Actividades Seleccionadas:
                    {{ filteredActivitySlices.length }}
                  </h2>
                  <v-data-table
                    :headers="activitySlicesFreightHeaders"
                    :items="filteredActivitySlices"
                  >
                  </v-data-table>
                </div>
              </div>
              <span v-if="freightTab == 0">
                Al apretar <strong> Guardar </strong> se
                <strong> modificaran </strong> todos los
                <strong> precios actuales </strong>.
              </span>
              <span v-if="freightTab == 1">
                Al apretar <strong> Guardar </strong> se guardaran
                <strong> unicamente </strong> los precios modificados.
              </span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="unsetFreightDialog">
                Cancelar
              </v-btn>
              <v-btn
                v-if="freightTab == 0"
                color="green darken-1"
                text
                @click="saveFilteredFreightBulkUpdate"
                :disabled="
                  !freightBulkUpdateValue && freightBulkUpdateValue !== 0
                "
              >
                Guardar Precios
              </v-btn>
              <v-btn
                v-if="freightTab == 1"
                color="green darken-1"
                text
                @click="saveGranularFreightBulkUpdate"
                :disabled="!hasNewFreightValues"
              >
                Guardar Precios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!activitySlices.length"
          @click="setHarvestCostDialog"
        >
          <v-icon> mdi-tractor </v-icon>

          <span class="px-2">Actualizar Cosecha</span>
        </v-btn>
        <v-dialog v-model="harvestCostBulkUpdateDialog" max-width="1000">
          <v-card>
            <v-card-title class="text-h5"> Actualizar Cosecha </v-card-title>

            <v-card-text>
              <v-tabs
                class="my-5"
                v-model="harvestCostTab"
                background-color="white"
                color="primary"
                dark
              >
                <v-tab class="text--secondary">Por filtros</v-tab>
                <v-tab class="text--secondary">Granular</v-tab>
              </v-tabs>

              <v-tabs-items v-model="harvestCostTab">
                <v-tab-item>
                  <div class="d-flex flex-row mx-2">
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      hide-details
                      dense
                      clearable
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      hide-details
                      v-model="speciesIdFilter"
                      clearable
                      dense
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-text-field
                      label="Nuevo Porcentaje Cosecha"
                      outlined
                      dense
                      type="number"
                      persistent-hint
                      :disabled="filteredActivitySlices.length < 1"
                      class="managrx-input ma-2 pb-2"
                      v-model.number="harvestCostBulkUpdateValue"
                    ></v-text-field>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="scrollable-holder">
                    <div
                      class="mt-2"
                      v-for="(activity, i) in harvestCostBulkUpdateActivities"
                      :key="activity.activitySliceId"
                    >
                      <v-form class="pr-2 pl-2" lazy-validation ref="form">
                        <div
                          class="d-flex"
                          :class="{
                            'background-muted':
                              parsedActivitySlices[i].harvestPercentCost ===
                              activity.harvestPercentCost,
                          }"
                        >
                          <v-icon
                            v-if="
                              parsedActivitySlices[i].harvestPercentCost !==
                              activity.harvestPercentCost
                            "
                            color="warning darken--2"
                            class="mr-2 mb-3 pb-3"
                            >mdi-pencil</v-icon
                          >
                          <v-text-field
                            label="Establecimiento"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.establishmentName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.activityName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Precio"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            v-model.number="activity.forwardPrice"
                          ></v-text-field>

                          <v-text-field
                            label="Rinde"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            :value="activity.dynamicYield"
                          ></v-text-field>
                          <v-text-field
                            label="Hectáreas Asignadas"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.assignedHectares"
                          ></v-text-field>
                          <v-text-field
                            label="Cosecha Min."
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.minHarvestCost"
                          ></v-text-field>
                          <v-text-field
                            label="Cosecha Max."
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.maxHarvestCost"
                          ></v-text-field>
                          <v-text-field
                            label="Porcentaje de Cosecha %"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedActivitySlices[i].harvestPercentCost !==
                              activity.harvestPercentCost
                                ? `Valor original ${parsedActivitySlices[i].harvestPercentCost}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.harvestPercentCost"
                          ></v-text-field>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <div
                v-if="harvestCostTab == 0"
                class="d-flex align-center pa-0 ma-0 mb-2"
              >
                <div>
                  <h2 class="caption">
                    Actividades Seleccionadas:
                    {{ filteredActivitySlices.length }}
                  </h2>
                  <v-data-table
                    :headers="activitySlicesHarvestCostHeaders"
                    :items="filteredActivitySlices"
                  >
                  </v-data-table>
                </div>
              </div>
              <span v-if="harvestCostTab == 0">
                Al apretar <strong> Guardar </strong> se
                <strong> modificaran </strong> todos los
                <strong> porcentaje de cosecha seleccionados </strong>
              </span>
              <span v-if="harvestCostTab == 1">
                Al apretar <strong> Guardar </strong> se guardaran
                <strong> unicamente </strong> los valores modificados.
              </span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="unsetHarvestCostDialog"
              >
                Cancelar
              </v-btn>
              <v-btn
                v-if="harvestCostTab == 0"
                color="green darken-1"
                text
                @click="saveFilteredHarvestCostBulkUpdate"
                :disabled="
                  !harvestCostBulkUpdateValue &&
                  harvestCostBulkUpdateValue !== 0
                "
              >
                Guardar Valores
              </v-btn>
              <v-btn
                v-if="harvestCostTab == 1"
                color="green darken-1"
                text
                @click="saveGranularHarvestCostBulkUpdate"
                :disabled="!hasNewHarvestCostValues"
              >
                Guardar Valores
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col></v-row
    >
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!activitySlices.length"
          @click="setSalesExpensesDialog"
        >
          <v-icon> mdi-cart-arrow-up </v-icon>

          <span class="px-2">Actualizar Gastos de Venta</span>
        </v-btn>
        <v-dialog v-model="salesExpensesBulkUpdateDialog" max-width="1000">
          <v-card>
            <v-card-title class="text-h5">
              Actualizar Gastos de Venta
            </v-card-title>

            <v-card-text>
              <v-tabs
                class="my-5"
                v-model="salesExpensesTab"
                background-color="white"
                color="primary"
                dark
              >
                <v-tab class="text--secondary">Por filtros</v-tab>
                <v-tab class="text--secondary">Granular</v-tab>
              </v-tabs>

              <v-tabs-items v-model="salesExpensesTab">
                <v-tab-item>
                  <div class="d-flex flex-row mx-2">
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      hide-details
                      dense
                      clearable
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      hide-details
                      v-model="speciesIdFilter"
                      clearable
                      dense
                      outlined
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                  </div>
                  <div class="mt-2 mx-2">
                    <div class="d-flex ms-2">
                      <v-text-field
                        label=""
                        outlined
                        dense
                        filled
                        type="text"
                        class="managrx-input mr-2"
                        value="Valor"
                      ></v-text-field>
                      <v-text-field
                        label="Nevo Impuestos de Venta %"
                        outlined
                        dense
                        type="number"
                        class="managrx-input mr-2"
                        v-model.number="salesExpensesTaxBulkUpdateValue"
                      ></v-text-field>
                      <v-text-field
                        label="Nueva Comisión de Venta %"
                        outlined
                        dense
                        type="number"
                        class="managrx-input mr-2"
                        v-model.number="salesExpensesComissionBulkUpdateValue"
                      ></v-text-field>
                      <v-text-field
                        label="Nuevo Entregador"
                        outlined
                        dense
                        type="number"
                        class="managrx-input mr-2"
                        v-model.number="salesExpensesHandOverBulkUpdateValue"
                      ></v-text-field>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="scrollable-holder">
                    <div
                      class="mt-2"
                      v-for="(activity, i) in salesExpensesBulkUpdateActivities"
                      :key="activity.activitySliceId"
                    >
                      <v-form class="pr-2 pl-2" lazy-validation ref="form">
                        <div
                          class="d-flex"
                          :class="{
                            'background-muted':
                              parsedActivitySlices[i].saleTax ===
                                activity.saleTax &&
                              parsedActivitySlices[i].saleComission ===
                                activity.saleComission &&
                              parsedActivitySlices[i].saleHandOver ===
                                activity.saleHandOver,
                          }"
                        >
                          <v-icon
                            v-if="
                              parsedActivitySlices[i].saleTax !==
                                activity.saleTax ||
                              parsedActivitySlices[i].saleComission !==
                                activity.saleComission ||
                              parsedActivitySlices[i].saleHandOver !==
                                activity.saleHandOver
                            "
                            color="warning darken--2"
                            class="mr-2 mb-3 pb-3"
                            >mdi-pencil</v-icon
                          >
                          <v-text-field
                            label="Establecimiento"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.establishmentName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.activityName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Precio"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            v-model.number="activity.forwardPrice"
                          ></v-text-field>

                          <v-text-field
                            label="Rinde"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            :value="activity.dynamicYield"
                          ></v-text-field>
                          <v-text-field
                            label="Hectáreas Asignadas"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="activity.assignedHectares"
                          ></v-text-field>
                          <v-text-field
                            label="Impuestos de Venta %"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedActivitySlices[i].saleTax !==
                              activity.saleTax
                                ? `Valor original ${parsedActivitySlices[i].saleTax}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.saleTax"
                          ></v-text-field>
                          <v-text-field
                            label="Comisiones de Venta %"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedActivitySlices[i].saleComission !==
                              activity.saleComission
                                ? `Valor original ${parsedActivitySlices[i].saleComission}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.saleComission"
                          ></v-text-field>
                          <v-text-field
                            label="Entregador USD/tn"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedActivitySlices[i].saleHandOver !==
                              activity.saleHandOver
                                ? `Valor original ${parsedActivitySlices[i].saleHandOver}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="activity.saleHandOver"
                          ></v-text-field>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <div
                v-if="salesExpensesTab == 0"
                class="d-flex align-center pa-0 ma-0 mb-2"
              >
                <div>
                  <h2 class="caption">
                    Actividades Seleccionadas:
                    {{ filteredActivitySlices.length }}
                  </h2>
                  <v-data-table
                    :headers="activitySlicesSalesExpensesHeaders"
                    :items="filteredActivitySlices"
                  >
                  </v-data-table>
                </div>
              </div>
              <span v-if="salesExpensesTab == 0">
                Al apretar <strong> Guardar </strong> se
                <strong> modificaran </strong> todos los
                <strong> valores seleccionados </strong>.
              </span>
              <span v-if="salesExpensesTab == 1">
                Al apretar <strong> Guardar </strong> se guardaran
                <strong> unicamente </strong> los valores modificados.
              </span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="unsetSalesExpensesDialog"
              >
                Cancelar
              </v-btn>
              <v-btn
                v-if="salesExpensesTab == 0"
                color="green darken-1"
                text
                @click="saveFilteredSalesExpensesBulkUpdate"
                :disabled="
                  !salesExpensesTaxBulkUpdateValue &&
                  salesExpensesTaxBulkUpdateValue !== 0 &&
                  !salesExpensesComissionBulkUpdateValue &&
                  salesExpensesComissionBulkUpdateValue !== 0 &&
                  !salesExpensesHandOverBulkUpdateValue &&
                  salesExpensesHandOverBulkUpdateValue !== 0
                "
              >
                Guardar Valores
              </v-btn>
              <v-btn
                v-if="salesExpensesTab == 1"
                color="green darken-1"
                text
                @click="saveGranularSalesExpensesBulkUpdate"
                :disabled="!hasNewSalesExpensesValues"
              >
                Guardar Valores
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog></v-col
      ></v-row
    >
    <v-row no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="red white--text px-2"
          dark
          @click.stop="deleteCampaignVersionDialog = true"
        >
          <v-icon> mdi-delete </v-icon> <span class="px-2">Borrar</span>
        </v-btn>

        <v-dialog v-model="deleteCampaignVersionDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea eliminar esta Versión de Campaña ?
            </v-card-title>

            <v-card-text>
              Al apretar Eliminar se borraran todos los datos ingresados para
              esta Campaña y no podran ser recuperados.
            </v-card-text>
            <v-card-text>
              <div class="">
                <v-text-field
                  v-model="inputName"
                  label="Nombre de Campaña"
                  type="text"
                  single-line
                ></v-text-field>
              </div>
              <div class="">
                <v-text-field
                  v-model.number="inputVersion"
                  type="number"
                  label="Versión de campaña"
                  single-line
                ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="deleteCampaignVersionDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn color="green darken-1" text @click="deleteCampaignVersion">
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="primary white--text px-2"
          dark
          @click.stop="quickCopyDialog = true"
        >
          <v-icon> mdi-content-copy </v-icon>
          <span class="px-2">Incrementar Versión</span>
        </v-btn>

        <v-dialog v-model="quickCopyDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea Aumentar la versión de esta campaña?
            </v-card-title>

            <v-card-text>
              Al apretar Continuar se guardara el estado actual de esta campaña
              y se generara una campaña con un incremento de la versión.
              <br />
              <strong
                >Solo se puede aumentar la versión de una campaña si no se hizo
                previamente.</strong
              >
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="quickCopyDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn color="green darken-1" text @click="saveCampaignQuickCopy">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.high-light-on-focus:hover {
  color: green !important;
}
</style>
<script>
import { putRequest } from "../../../managrx/serverCall";
import { parseToLocaleString } from "../../../managrx/utils";
export default {
  name: "AbstractCampaignActions",
  props: {
    campaignVersion: Number,
    campaignName: String,
    establishments: Array,
    activitySlices: Array,
    regions: Array,
    species: Array,
  },
  computed: {
    hasNewSalesExpensesValues() {
      for (const activity of this.filteredActivitySlices) {
        let oldValues = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        );
        if (
          oldValues?.saleTax !== activity.saleTax ||
          oldValues?.saleHandOver !== activity.saleHandOver ||
          oldValues?.saleComission !== activity.saleComission
        ) {
          return true;
        }
      }
      return false;
    },
    hasNewHarvestCostValues() {
      for (const activity of this.harvestCostBulkUpdateActivities) {
        let oldValue = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.harvestPercentCost;
        if (oldValue !== activity.harvestPercentCost) {
          return true;
        }
      }
      return false;
    },
    hasNewYieldValues() {
      for (const activity of this.yieldBulkUpdateActivities) {
        let oldValue = this.parsedFilteredActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.forwardYield;
        if (oldValue !== activity.forwardYield) {
          return true;
        }
      }
      return false;
    },
    hasNewFreightValues() {
      for (const activity of this.freightBulkUpdateActivities) {
        let oldValue = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.freightCostTn;
        if (oldValue !== activity.freightCostTn) {
          return true;
        }
      }
      return false;
    },
    filteredActivitySlices() {
      let data = this.activitySlices.map((el) => {
        return {
          ...el,
          saleTax: parseToLocaleString(
            Number(el?.saleTax?.replace(",", "")) * 100
          ),
          saleComission: parseToLocaleString(
            Number(el?.saleComission?.replace(",", "")) * 100
          ),
        };
      });
      if (this.regionIdFilter) {
        data = data.filter((el) => el.regionId == this.regionIdFilter);
      }
      if (this.speciesIdFilter) {
        data = data.filter((el) => el.speciesId == this.speciesIdFilter);
      }
      if (this.establishmentIdFilter) {
        data = data.filter(
          (el) => el.establishmentId == this.establishmentIdFilter
        );
      }
      return data;
    },
    parsedFilteredActivitySlices() {
      return this.filteredActivitySlices.map((el) => {
        return {
          engagementId: el?.engagementId,
          establishmentName: el?.establishmentName,
          activitySliceId: el?.activitySliceId,
          activityName: el?.activityName,
          forwardPrice: el?.forwardPrice,
          assignedHectares: el?.assignedHectares,
          dynamicYield: el?.dynamicYield,
          forwardYield: Number(el?.forwardYield?.replace(",", "")),
          minHarvestCost: el?.minHarvestCost,
          maxHarvestCost: el?.maxHarvestCost,
          saleTax: Number(el?.saleTax?.replace(",", "")) * 100,
          saleComission: Number(el?.saleComission?.replace(",", "")) * 100,
          saleHandOver: Number(el?.saleHandOver?.replace(",", "")),
          freightCostTn: Number(el?.freightCostTn?.replace(",", "")),
          harvestPercentCost: Number(el?.harvestPercentCost?.replace(",", "")),
        };
      });
    },
    parsedActivitySlices() {
      return this.activitySlices.map((el) => {
        return {
          engagementId: el?.engagementId,
          establishmentName: el?.establishmentName,
          activitySliceId: el?.activitySliceId,
          activityName: el?.activityName,
          forwardPrice: el?.forwardPrice,
          assignedHectares: el?.assignedHectares,
          dynamicYield: el?.dynamicYield,
          forwardYield: Number(el?.forwardYield?.replace(",", "")),
          minHarvestCost: el?.minHarvestCost,
          maxHarvestCost: el?.maxHarvestCost,
          saleTax: Number(el?.saleTax?.replace(",", "")) * 100,
          saleComission: Number(el?.saleComission?.replace(",", "")) * 100,
          saleHandOver: Number(el?.saleHandOver?.replace(",", "")),
          freightCostTn: Number(el?.freightCostTn?.replace(",", "")),
          harvestPercentCost: Number(el?.harvestPercentCost?.replace(",", "")),
        };
      });
    },
  },
  methods: {
    clearFilters() {
      this.regionIdFilter = null;
      this.speciesIdFilter = null;
      this.establishmentIdFilter = null;
    },
    // Yield
    async saveGranularYieldBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.yieldBulkUpdateDialog = false;

      let payloads = {};
      for (const activity of this.yieldBulkUpdateActivities) {
        let engId = activity.engagementId;

        let oldValue = this.parsedFilteredActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.forwardYield;

        if (oldValue !== activity.forwardYield) {
          if (payloads[engId]) {
            payloads[engId].bulkUpdate.push({
              id: activity.activitySliceId,
              forwardYield: activity.forwardYield,
            });
          } else {
            payloads[engId] = {
              bulkUpdate: [
                {
                  id: activity.activitySliceId,
                  forwardYield: activity.forwardYield,
                },
              ],
            };
          }
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/yield/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    updateYieldBulkUpdateActivities() {
      this.yieldBulkUpdateActivities = this.parsedFilteredActivitySlices.map(
        (item) => ({ ...item })
      );
    },
    setYieldDialog() {
      this.yieldBulkUpdateDialog = true;
      this.yieldBulkUpdateValue = null;
      this.yieldTab = 0;
      // This avoid to create a shallow copy
      this.yieldBulkUpdateActivities = this.parsedFilteredActivitySlices.map(
        (item) => ({ ...item })
      );
    },
    unsetYieldDialog() {
      this.yieldBulkUpdateDialog = false;
      this.yieldBulkUpdateValue = null;
      this.yieldTab = 0;
    },
    // Freight
    async saveFilteredFreightBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.freightBulkUpdateDialog = false;

      let payloads = {};
      for (const activity of this.filteredActivitySlices) {
        let engId = activity.engagementId;

        if (payloads[engId]) {
          payloads[engId].bulkUpdate.push({
            id: activity.activitySliceId,
            freightCostTn: this.freightBulkUpdateValue,
          });
        } else {
          payloads[engId] = {
            bulkUpdate: [
              {
                id: activity.activitySliceId,
                freightCostTn: this.freightBulkUpdateValue,
              },
            ],
          };
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/freight/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async saveGranularFreightBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.freightBulkUpdateDialog = false;

      let payloads = {};
      for (const activity of this.freightBulkUpdateActivities) {
        let engId = activity.engagementId;

        let oldValue = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.freightCostTn;

        if (oldValue !== activity.freightCostTn) {
          if (payloads[engId]) {
            payloads[engId].bulkUpdate.push({
              id: activity.activitySliceId,
              freightCostTn: activity.freightCostTn,
            });
          } else {
            payloads[engId] = {
              bulkUpdate: [
                {
                  id: activity.activitySliceId,
                  freightCostTn: activity.freightCostTn,
                },
              ],
            };
          }
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/freight/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    setFreightDialog() {
      this.freightBulkUpdateDialog = true;
      this.freightBulkUpdateValue = null;
      this.freightTab = 0;
      // This avoid to create a shallow copy
      this.freightBulkUpdateActivities = this.parsedActivitySlices.map(
        (item) => ({ ...item })
      );
    },
    unsetFreightDialog() {
      this.freightBulkUpdateDialog = false;
      this.freightBulkUpdateValue = null;
      this.freightTab = 0;
      this.clearFilters();
    },
    // Harvest cost
    async saveFilteredHarvestCostBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.harvestCostBulkUpdateDialog = false;

      let payloads = {};

      for (const activity of this.filteredActivitySlices) {
        let engId = activity.engagementId;

        if (payloads[engId]) {
          payloads[engId].bulkUpdate.push({
            id: activity.activitySliceId,
            harvestPercentCost: this.harvestCostBulkUpdateValue,
          });
        } else {
          payloads[engId] = {
            bulkUpdate: [
              {
                id: activity.activitySliceId,
                harvestPercentCost: this.harvestCostBulkUpdateValue,
              },
            ],
          };
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/harvest/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    // Harvest cost
    async saveGranularHarvestCostBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.harvestCostBulkUpdateDialog = false;

      let payloads = {};

      for (const activity of this.harvestCostBulkUpdateActivities) {
        let engId = activity.engagementId;

        let oldValue = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        )?.harvestPercentCost;
        if (oldValue !== activity.harvestPercentCost) {
          if (payloads[engId]) {
            payloads[engId].bulkUpdate.push({
              id: activity.activitySliceId,
              harvestPercentCost: activity.harvestPercentCost,
            });
          } else {
            payloads[engId] = {
              bulkUpdate: [
                {
                  id: activity.activitySliceId,
                  harvestPercentCost: activity.harvestPercentCost,
                },
              ],
            };
          }
        }
      }
      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/harvest/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    setHarvestCostDialog() {
      this.harvestCostBulkUpdateDialog = true;
      this.harvestCostBulkUpdateValue = null;
      this.harvestCostTab = 0;
      this.clearFilters();

      // This avoid to create a shallow copy
      this.harvestCostBulkUpdateActivities = this.parsedActivitySlices.map(
        (item) => ({ ...item })
      );
    },
    unsetHarvestCostDialog() {
      this.harvestCostBulkUpdateDialog = false;
      this.harvestCostBulkUpdateValue = null;
      this.harvestCostTab = 0;
      this.clearFilters();
    },

    // Sales Expenses
    async saveFilteredSalesExpensesBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.salesExpensesBulkUpdateDialog = false;

      let payloads = {};
      for (const activity of this.filteredActivitySlices) {
        let engId = activity.engagementId;

        if (payloads[engId]) {
          payloads[engId].bulkUpdate.push({
            id: activity.activitySliceId,
            saleTax: this.salesExpensesTaxBulkUpdateValue
              ? this.salesExpensesTaxBulkUpdateValue / 100
              : this.salesExpensesTaxBulkUpdateValue,
            saleComission: this.salesExpensesComissionBulkUpdateValue
              ? this.salesExpensesComissionBulkUpdateValue / 100
              : this.salesExpensesComissionBulkUpdateValue,
            saleHandOver: this.salesExpensesHandOverBulkUpdateValue,
          });
        } else {
          payloads[engId] = {
            bulkUpdate: [
              {
                id: activity.activitySliceId,
                saleTax: this.salesExpensesTaxBulkUpdateValue
                  ? this.salesExpensesTaxBulkUpdateValue / 100
                  : this.salesExpensesTaxBulkUpdateValue,
                saleComission: this.salesExpensesComissionBulkUpdateValue
                  ? this.salesExpensesComissionBulkUpdateValue / 100
                  : this.salesExpensesComissionBulkUpdateValue,
                saleHandOver: this.salesExpensesHandOverBulkUpdateValue,
              },
            ],
          };
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/sales-expenses/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    // Sales Expenses
    async saveGranularSalesExpensesBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.salesExpensesBulkUpdateDialog = false;

      let payloads = {};
      for (const activity of this.salesExpensesBulkUpdateActivities) {
        let engId = activity.engagementId;

        let oldValues = this.parsedActivitySlices?.find(
          (el) => el.activitySliceId == activity.activitySliceId
        );

        let newValues = {};
        if (oldValues?.saleTax !== activity?.saleTax) {
          newValues.saleTax = activity.saleTax / 100;
        }
        if (oldValues?.saleComission !== activity?.saleComission) {
          newValues.saleComission = activity.saleComission / 100;
        }
        if (oldValues?.saleHandOver !== activity?.saleHandOver) {
          newValues.saleHandOver = activity.saleHandOver;
        }
        console.log(newValues);
        if (Object.keys(newValues).length > 0) {
          if (payloads[engId]) {
            payloads[engId].bulkUpdate.push({
              id: activity.activitySliceId,
              ...newValues,
            });
          } else {
            payloads[engId] = {
              bulkUpdate: [
                {
                  id: activity.activitySliceId,
                  ...newValues,
                },
              ],
            };
          }
        }
      }

      for (const engId in payloads) {
        let response = await putRequest(
          `/module/put/engagement/sales-expenses/${engId}`,
          payloads[engId]
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave");
      this.clearFilters();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    setSalesExpensesDialog() {
      this.salesExpensesBulkUpdateDialog = true;
      this.salesExpensesTaxBulkUpdateValue = null;
      this.salesExpensesComissionBulkUpdateValue = null;
      this.salesExpensesHandOverBulkUpdateValue = null;
      this.salesExpensesTab = 0;
      // This avoid to create a shallow copy
      this.salesExpensesBulkUpdateActivities = this.parsedActivitySlices.map(
        (item) => ({ ...item })
      );
    },
    unsetSalesExpensesDialog() {
      this.salesExpensesBulkUpdateDialog = false;
      this.salesExpensesTaxBulkUpdateValue = null;
      this.salesExpensesComissionBulkUpdateValue = null;
      this.salesExpensesHandOverBulkUpdateValue = null;
      this.salesExpensesTab = 0;
      this.clearFilters();
    },
    deleteCampaignVersion() {
      this.deleteCampaignVersionDialog = false;
      let deleteCampaignVersion = true;
      if (this.campaignVersion != this.inputVersion) {
        deleteCampaignVersion = false;
        this.$emit("badCampaignVersion");
      }
      if (this.campaignName != this.inputName) {
        deleteCampaignVersion = false;
        this.$emit("badCampaignName");
      }
      if (deleteCampaignVersion) {
        this.$emit("deleteSingleCampaignVersion");
      } else {
        this.inputName = null;
        this.inputVersion = null;
      }
    },
    saveCampaignQuickCopy() {
      this.quickCopyDialog = false;
      this.$emit("saveCampaignQuickCopy");
    },
  },
  data() {
    return {
      speciesIdFilter: null,
      regionIdFilter: null,
      establishmentIdFilter: null,
      // Yield
      yieldTab: 0,
      yieldBulkUpdateValue: null,
      yieldBulkUpdateActivities: [],
      yieldBulkUpdateDialog: false,
      // Freight
      freightTab: 0,
      activitySlicesFreightHeaders: [
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Nombre",
          value: "activityName",
        },

        {
          text: "Precio",
          value: "forwardPrice",
        },
        {
          text: "Rinde",
          value: "dynamicYield",
        },
        {
          text: "Hectáreas Asignadas",
          value: "assignedHectares",
        },
        { text: "Precio Flete", value: "freightCostTn" },
      ],
      freightBulkUpdateValue: null,
      freightBulkUpdateActivities: [],
      freightBulkUpdateDialog: false,
      // Harvest Cost
      harvestCostTab: 0,
      activitySlicesHarvestCostHeaders: [
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Nombre",
          value: "activityName",
        },

        {
          text: "Precio",
          value: "forwardPrice",
        },
        {
          text: "Rinde",
          value: "dynamicYield",
        },
        {
          text: "Hectáreas Asignadas",
          value: "assignedHectares",
        },
        { text: "Cosecha Min.", value: "minHarvestCost" },
        { text: "Cosecha Max.", value: "maxHarvestCost" },
        { text: "Porcentaje de Cosecha", value: "harvestPercentCost" },
      ],
      harvestCostBulkUpdateValue: null,
      harvestCostBulkUpdateActivities: [],
      harvestCostBulkUpdateDialog: false,
      // Sales Expenses
      salesExpensesTab: 0,
      activitySlicesSalesExpensesHeaders: [
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Nombre",
          value: "activityName",
        },

        {
          text: "Precio",
          value: "forwardPrice",
        },
        {
          text: "Rinde",
          value: "dynamicYield",
        },
        {
          text: "Hectáreas Asignadas",
          value: "assignedHectares",
        },
        { text: "Impuesto de Venta %", value: "saleTax" },
        { text: "Comisiones de Venta %", value: "saleComission" },
        { text: "Entregador USD/tn", value: "saleHandOver" },
      ],
      salesExpensesTaxBulkUpdateValue: null,
      salesExpensesComissionBulkUpdateValue: null,
      salesExpensesHandOverBulkUpdateValue: null,
      salesExpensesBulkUpdateActivities: [],
      salesExpensesBulkUpdateDialog: false,

      // Data
      deleteCampaignVersionDialog: false,
      inputName: null,
      inputVersion: null,
      quickCopyDialog: false,
    };
  },
};
</script>

<style></style>
