<template>
  <v-container>
    <v-row class="pa-5">
      <v-col>
        <div>
          <div class="d-flex flex-column ml-4 mr-4 mt-4">
            <div>
              <v-select
                label="Establecimiento"
                class="mr-2"
                outlined
                dense
                v-model="newEngagement.establishmentId"
                :items="establishments"
                @change="setDefaultPhysicalHectares"
                item-text="name"
                item-value="id"
              >
              </v-select>
              <v-text-field
                v-model.number="newEngagement.physicalHectares"
                type="number"
                dense
                outlined
                label="Hectáreas físicas a asignar"
              ></v-text-field>
              <v-text-field
                v-model.number="newEngagement.rentQqHa"
                type="number"
                dense
                outlined
                label="Alquiler (Qq/Ha)"
              ></v-text-field>
              <v-text-field
                v-model.number="newEngagement.variableParticipationAsInt"
                type="number"
                dense
                outlined
                :rules="participationRules"
                append-icon="mdi-percent-outline"
                label="(0-100)% Participación variable"
              ></v-text-field>
              <v-btn
                color="blue white--text"
                class="col-12"
                :disabled="
                  participationRules.find((el) => el != true) ? true : false
                "
                @click="saveNewEngagement"
                depressed
              >
                <v-icon> mdi-floppy </v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONTRACTS } from "../../../managrx/constants";
import { NEW_CAMPAIGN_ENGAGEMENT } from "../../../managrx/formsFieldsRules";
import { validateForm } from "../../../managrx/utils";
export default {
  name: "AbstractCampaignNewEngagement",
  props: {
    campaignId: Number,
    establishments: Array,
  },
  methods: {
    setDefaultPhysicalHectares(){
      this.newEngagement.physicalHectares = this.physicalHectares
    },
    async saveNewEngagement() {
      let payload = {
        campaignId: this.campaignId,
        establishmentId: this.newEngagement.establishmentId,
        materializationStatus: this.newEngagement.materializationStatus,
        rentQqHa: this.newEngagement.rentQqHa,
        variableParticipation: this.variableParticipationAsDecimal,
        rentQqActivity: 18, // SOJA DE PRIMERA
        contractType: this.newEngagement.contractType,
        physicalHectares: this.newEngagement.physicalHectares,
      };
      this.$emit("saveNewEngagement", payload);
    },
  },
  computed: {
    physicalHectares() {
      return this.establishments.find(
        (el) => el.id == this.newEngagement?.establishmentId
      )?.hectares;
    },
    variableParticipationAsDecimal() {
      return this.newEngagement.variableParticipationAsInt / 100;
    },
    participationRules() {
      let fieldRules = NEW_CAMPAIGN_ENGAGEMENT?.variableParticipation;

      let detail = validateForm(
        this.newEngagement.variableParticipationAsInt,
        fieldRules
      );
      return detail;
    },
  },

  data() {
    return {
      contracts: CONTRACTS,
      newEngagement: {
        materializationStatus: 4,
        establishmentId: null,
        physicalHectares: null,
        rentQqHa: null,
        contractType: null,
        variableParticipationAsInt: 15,
      },
    };
  },
};
</script>

<style></style>
